<template>
    <BaseContainer
        id="hero"
        as="section"
        :aria-label="t('title', { emphasizedWord: t('title-emphasized-word') })"
        class="relative pb-4 pt-16 text-center md:pb-28 md:pt-40 lg:pt-32"
    >
        <!-- Title -->
        <i18n-t
            scope="parent"
            keypath="title"
            tag="h1"
            class="mx-auto max-w-4xl text-5xl font-medium text-slate-900 sm:text-7xl"
        >
            <template #emphasizedWord>
                <span
                    class="relative inline-block whitespace-nowrap text-blue-600"
                >
                    <svg
                        aria-hidden="true"
                        viewBox="0 0 418 42"
                        class="absolute left-0 top-11 z-[-1] h-[0.58em] w-full fill-blue-300/70 sm:top-16"
                        preserveAspectRatio="none"
                    >
                        <path
                            class="path-animation"
                            d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"
                        />
                    </svg>

                    <span class="gradient-text relative">
                        {{ t('title-emphasized-word') }}
                    </span>
                </span>
            </template>
        </i18n-t>

        <p class="mx-auto mt-7 text-lg text-slate-700">
            {{ t('subtitle') }}
        </p>

        <div
            class="mt-8 flex flex-col justify-center gap-3 gap-x-3 sm:gap-x-6 md:flex-row md:gap-4"
        >
            <BaseButton :to="registerUrl" target="_blank">
                {{ t('main-action-btn') }}
            </BaseButton>

            <BaseButton to="#features" variant="outline">
                {{ t('secondary-action-btn') }}
            </BaseButton>
        </div>
    </BaseContainer>
</template>

<script setup lang="ts">
const { registerUrl } = useAppUrls()
const { t } = useI18n()
</script>

<style>
.path-animation {
    stroke: theme('colors.blue.200');
    stroke-width: 4;
    fill: none;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: draw 2.5s forwards cubic-bezier(0.94, 0.45, 0.12, 0.99);
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

.gradient-text {
    background-color: #1e2ec1;
    background-image: linear-gradient(
        to right,
        theme('colors.blue.400'),
        theme('colors.blue.700')
    );
    background-clip: text;

    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}
</style>

<i18n lang="yaml">
en:
    title: 'Accounting {emphasizedWord} for your business'
    title-emphasized-word: 'made simple'
    subtitle: 'Issuing documents, financial analysis in a shared workspace and more.'
    main-action-btn: 'Register for free'
    secondary-action-btn: 'Learn more'
bg:
    title: '{emphasizedWord} счетоводство за Вашия бизнес'
    title-emphasized-word: 'Улеснено'
    subtitle: 'Счетоводен софтуер за издаване на документи, финансов анализ, споделено работно пространство и още.'
    main-action-btn: 'Безплатна регистрация'
    secondary-action-btn: 'Научи повече'
</i18n>
