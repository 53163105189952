<template>
    <BaseContainer as="section">
        <dl class="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
            <div
                v-for="feature in features"
                :key="feature.name"
                class="flex flex-col"
            >
                <dt
                    class="flex items-center gap-x-3 text-base/7 font-semibold text-gray-900"
                >
                    <component
                        :is="feature.icon"
                        class="size-6 flex-none text-blue-600"
                        aria-hidden="true"
                    />
                    <h3 class="text-lg">{{ feature.name }}</h3>
                </dt>
                <dd
                    class="mt-4 flex flex-auto flex-col text-base/7 text-gray-600"
                >
                    <p class="flex-auto">{{ feature.description }}</p>
                </dd>
            </div>
        </dl>
    </BaseContainer>
</template>

<script setup lang="ts">
import {
    ChartBarIcon,
    ClipboardDocumentListIcon,
    DocumentTextIcon,
    FingerPrintIcon,
    LanguageIcon,
    UserGroupIcon,
} from '@heroicons/vue/24/solid'

const { t } = useI18n()

const features = computed(() => {
    return [
        {
            name: t('features.1.name'),
            description: t('features.1.description'),
            icon: DocumentTextIcon,
        },
        {
            name: t('features.2.name'),
            description: t('features.2.description'),
            icon: FingerPrintIcon,
        },
        {
            name: t('features.3.name'),
            description: t('features.3.description'),
            icon: LanguageIcon,
        },
        {
            name: t('features.4.name'),
            description: t('features.4.description'),
            icon: ClipboardDocumentListIcon,
        },
        {
            name: t('features.5.name'),
            description: t('features.5.description'),
            icon: ChartBarIcon,
        },
        {
            name: t('features.6.name'),
            description: t('features.6.description'),
            icon: UserGroupIcon,
        },
    ]
})
</script>

<i18n lang="yaml">
en:
    features:
        1:
            name: 'Invoice issuance'
            description: 'Create invoices in different currencies, export them to PDF, and send them directly via email. A simple and convenient solution for professional documents.'
        2:
            name: 'AI for expense document recognition'
            description: 'Automated recognition of data from PDFs and images, filling out forms in seconds. Saves time and minimizes the risk of errors.'
        3:
            name: 'Bilingual support'
            description: 'Work with documents in Bulgarian and English. Suitable for international clients and partners, with easy switching between languages.'
        4:
            name: 'Comprehensive management'
            description: 'Manage income, expenses, contacts, and items all in one place. Everything needed to track your business’s financial health.'
        5:
            name: 'Real-time financial analysis'
            description: 'Get detailed reports and graphs for income, expenses, and assets. Make informed decisions with up-to-date data at any time.'
        6:
            name: 'Team roles and permissions'
            description: 'Create custom roles with controlled permissions for your team. Invite your accountant or colleagues and collaborate securely on the platform.'
bg:
    features:
        1:
            name: 'Издаване на фактури'
            description: 'Създавайте фактури в различни валути, експортирайте ги в PDF и ги изпращайте директно по имейл. Лесно и удобно решение за професионални документи.'
        2:
            name: 'AI за разпознаване на разходни документи'
            description: 'Автоматизирано разпознаване на данни от PDF и изображения, което попълва формуляри за секунди. Спестява време и минимизира риска от грешки.'
        3:
            name: 'Двуезична поддръжка'
            description: 'Работете с документи на български и английски език. Подходящо за международни клиенти и партньори, с лесно превключване между езиците.'
        4:
            name: 'Цялостно управление'
            description: 'Управлявайте приходи, разходи, контакти и артикули на едно място. Всичко необходимо за проследяване на финансовото състояние на бизнеса ви.'
        5:
            name: 'Финансови анализи в реално време'
            description: 'Получавайте подробни отчети и графики за приходи, разходи и активи. Вземайте информирани решения с актуални данни по всяко време.'
        6:
            name: 'Роли и права за екипи'
            description: 'Създавайте персонализирани роли с контролирани права за екипа си. Поканете вашия счетоводител или колеги и работете заедно в сигурна платформа.'
</i18n>
